<template>
  <div class="pb-1">
    <div class="d-flex justify-space-between align-center">
      <div>
        <div class="d-flex align-center">
          <v-breadcrumbs :items="breadcrumbs" class="pl-0 pb-1 pt-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :exact="item.exact" :disabled="item.disabled" :to="item.to">
                <slot v-if="item.text == feature.text" :item="item" name="default">
                  {{ item.text }}
                </slot>
                <breadcrumb-parcel v-else-if="item.text == 'Parcels'"></breadcrumb-parcel>
                <span v-else>
                  {{ item.text }}
                </span>
                <ParcelReference
                  :reference="parcelReference"
                  v-if="item.text === feature.text && parcelReference"
                  x-small
                />
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <div class="d-none align-center d-sm-flex">
            <slot name="action"></slot>
            <v-btn icon @click="showMap()"> <v-icon>mdi-map</v-icon></v-btn>
          </div>
        </div>
      </div>
      <div v-if="features.length > 1">
        <v-menu v-if="currentFeature" transition="scale-transition">
          <template #activator="{ on }">
            <v-btn
              color="primary lighten-3"
              class="v-btn--active text-capitalize"
              small
              text
              v-on="on"
            >
              <v-icon v-if="currentFeature.style.icon" small :color="currentFeature.style.color">
                {{ currentFeature.style.icon }}
              </v-icon>
              <span :style="`color: ${currentFeature.style.color}`">
                <span class="ml-1">
                  {{ currentFeature.text }}
                </span>
              </span>
            </v-btn>
          </template>
          <v-list nav>
            <v-btn
              :to="`/parcels/${$route.params.id}/${ft.url}`"
              text
              block
              v-for="ft in features"
              :key="ft.text"
              class="text-capitalize mb-1"
              :color="activeFeature(ft) ? 'primary' : ''"
              :class="{ 'v-btn--active': activeFeature(ft) }"
            >
              <v-icon v-if="ft.style.icon" small :color="ft.style.color">
                {{ ft.style.icon }}
              </v-icon>
              <span :style="`color: ${ft.style.color}`">
                <span class="ml-1">
                  {{ ft.text }}
                </span>
              </span>
            </v-btn>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="d-flex align-center d-sm-none flex-wrap">
      <slot name="action"></slot>
      <v-btn icon @click="showMap()"> <v-icon>mdi-map</v-icon></v-btn>
    </div>
  </div>
</template>

<script>
import parcel from '@/mixins/parcel';
import Feature from '../../services/feature';
const feature = new Feature();
import featureMixin from '../../mixins/feature';
import { geojsonCenter, parseGeoJSON } from '../../utils/map';
export default {
  mixins: [parcel, featureMixin],
  props: {
    feature: {
      type: Object
    },
    showAddress: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BreadcrumbParcel: () => import('../../components/Core/BreadcrumbParcel.vue'),
    ParcelReference: () => import('../Parcel/Reference.vue')
  },
  data: () => ({
    parcel: null,
    selectedFeature: null,
    parcel: null,
    features: []
  }),
  computed: {
    latestParcel() {
      return this.$store.state.parcel.latestParcel;
    },
    parcelReference() {
      const ref = this.$store.state.parcel.latestParcel?.reference;
      if (ref) {
        return ref;
      }
      return '';
    },
    parcelNumber() {
      let res = 'Loading';
      if (this.parcel) {
        res = this.parcel.number;
      }
      return res;
    },
    breadcrumbs() {
      return [
        {
          text: 'Parcels',
          to: '/parcels',
          exact: true
        },
        {
          text: this.parcelNumber,
          to: `/parcels?highlight=${this.$route.params.id}`,
          disabled: false
        }
        // { ...this.feature
        // }
      ];
    },
    streetAddressComputed() {
      let res = '';
      if (this.parcel.street_number) {
        res += `${this.parcel.street_number}`;
        res += ` `;
      }
      if (this.parcel.street_name) {
        res += `${this.parcel.street_name}`;
      }
      return res;
    },
    currentFeature() {
      let feature = this.$route.path;
      feature = feature.split('/');
      feature = feature[feature.length - 1];
      return this.getFeature(feature);
    }
  },
  async created() {
    this.generateFeatures();
  },
  methods: {
    activeFeature(ft) {
      return ft?.feature === this.currentFeature?.feature;
    },
    showMap() {
      localStorage.setItem('lastViewMode', 'ParcelMap');
      const id = this.$route.params.id;

      this.$store.commit('setParcelDetail', { key: 'id', value: null });
      this.$store.commit('setParcelDetail', { key: 'dialog', value: false });

      let url = '/parcels/map';

      const geojson = parseGeoJSON(this.latestParcel?.geojson);
      if (geojson) {
        const center = geojsonCenter(geojson);
        url += '?center=' + center[0] + ',' + center[1];
      }
      this.$router.push(url);
    },
    async getParcel() {
      if (this.latestParcel && this.latestParcel.id == this.$route.params.id) {
        this.parcel = this.copyObject(this.latestParcel);
      } else {
        this.parcel = await this.getParcelDetail(this.$route.params.id, true);
      }
      this.$store.commit('latestParcel', this.parcel);
      this.breadcrumbs[1].text = this.parcel.number + ` ` + this.streetAddressComputed;
    },
    async generateFeatures(value) {
      await this.getParcel();
      if (!value) {
        return;
      }
      const featuresList = [...this.$store.getters.featuresList];
      let features = { ...value };
      const { type } = this.$store.state.loggedInAs;
      if (type === 'organization') {
        if (this.parcel && this.parcel.features) {
          features = { ...features, ...this.parcel.features };
        }
      }

      this.features = featuresList.filter(
        ft => !!features[ft.feature] && feature.isEnabled(ft.feature)
      );
    },
    setFeature() {
      this.selectedFeature = this.currentFeature.feature;
    },
    onFeatureChange() {
      this.$router.push(
        `/parcels/${this.$route.params.id}/${this.getFeature(this.selectedFeature).url}`
      );
    },
    featureLink(f) {
      return `/parcels/${this.$route.params.id}/${f.url}`;
    }
  },
  watch: {
    currentFeature: {
      handler: 'setFeature',
      immediate: true
    },
    selectedFeature: {
      handler: 'onFeatureChange'
    },
    featuresComputed: {
      handler: 'generateFeatures',
      immediate: true
    }
  }
};
</script>

<style scoped></style>
